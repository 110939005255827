<template>
    <div class="email-verification-container">
            <div class="mb-3">
                <ValidationObserver ref="formEmailVerify">
                    <ValidationProvider name="email_code" rules="required" v-slot="{ valid, errors }">
                        <sms-input
                            v-model="emailCode"
                            :timerFrom="emailTimer"
                            @timerExpired="timerExpired"
                            class="responsive-sms-input"
                        />
                    </ValidationProvider>
                </ValidationObserver>
            </div>
            <b-row>
                <b-col>
                    <b-button
                        type="button"
                        variant="primary"
                        color="white"
                        size="lg"
                        block
                        @click="emailVerify"
                        :disabled="emailTimer === 0"
                        class="responsive-button"
                    >
                        {{ $t('verify').toUpperCase() }}
                    </b-button>
                </b-col>
                <b-col>
                    <b-button
                        type="submit"
                        variant="outline-secondary"
                        size="lg"
                        block
                        @click="emailSend()"
                        :disabled="emailTimer>0"
                        class="responsive-button"
                    >
                        {{ $t('send_again').toUpperCase() }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
</template>

<script>
// Components
import Loading from '@/components/elements/Loading.vue';
import SmsInput from '@/components/elements/SmsInput.vue';

// Services
// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';

export default {
    props:{
        expiresIn:Number
    },
    components: {
        Loading,
        SmsInput,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formLoading: false,
            email: this.emailAddress,
            emailTimer:null,
            emailCode: '',
        }
    },
    watch:{
        emailTimer(newVal,oldVal){
            this.emailTimer=newVal;
        }
    },
    created() {
      this.emailTimer =this.expiresIn;
    },
    methods: {
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        async emailVerify() {
            this.$emit('paymentVerify',this.emailCode)
        },
        timerExpired(event) {
            this.emailTimer = 0;
        },
        emailSend() {
            this.emailTimer = this.expiresIn;
            this.$emit('sendEmail')
        }
    }
}
</script>

<style scoped>
.email-verification-container {
    padding: 10px;
}

.responsive-sms-input input {
    font-size: 12px;
    height: 50px;
    width: 45px;
    text-align: left;
    margin: 0 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-weight: 700;
    color: #000;
}

.responsive-button {
    font-size: 16px;
    padding: 15px 10px;
}

@media (max-width: 768px) {
    .responsive-sms-input input {
        font-size: 12px;
        height: 40px;
        width: 35px;
    }

    .responsive-button {
        font-size: 12px;
        padding: 12px 8px;
    }
}

@media (max-width: 576px) {
    .responsive-sms-input input {
        font-size: 12px;
        height: 100px;
        width: 30px;
        margin: 0 3px;
    }

    .responsive-button {
        font-size: 12px;
        padding: 10px 6px;
    }

    .email-verification-container {
        padding: 10px;
    }
}
</style>
