<template>
    <div class="row">
        <div class="container py-4">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-center p-3 bg-light rounded mb-4">
                <div class="logo-wrapper mb-3 mb-md-0">
                    <img src="@/assets/img/BAU_Logo.png" height="80" alt="BAU Logo" class="img-fluid" />
                </div>
                <h3 class="mb-0">E-Payment System</h3>
            </div>
            <ValidationObserver ref="formReservationModalValidate">
                <b-card no-body class="shadow-sm mb-4">
                    <b-card-body>
                        <b-row>
                            <b-col xs="12" sm="6" md="6">
                                <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('Cardholder’s First Name')">
                                        <b-input-group prepend="*">
                                            <b-form-input v-model="formData.name" :state="errors[0] ? false : null" />
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="6" md="6">
                                <ValidationProvider name="surname" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('Cardholder’s Last Name')">
                                        <b-input-group prepend="*">
                                            <b-form-input v-model="formData.surname" :state="errors[0] ? false : null" />
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="6" md="6">
                                <ValidationProvider name="email" rules="required|email" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('E-mail')">
                                        <b-input-group prepend="*">
                                            <b-form-input v-model="formData.email" :state="errors[0] ? false : null" />
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="6" md="6">
                                <ValidationProvider name="country" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('Country')">
                                        <CountrySelectbox :public="true" v-model="formData.country_code" :valueType="'code'" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12">
                                <ValidationProvider name="notes" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('Notes/Explanation')">
                                        <b-form-textarea
                                            id="textarea"
                                            v-model="formData.note"
                                            rows="3"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>

                <!-- Ödeme Öğeleri Alanı -->
                <b-card no-body class="shadow-sm mb-4">
                    <b-card-body>
                        <b-alert show variant="primary" class="mb-4" >
                            <div v-html="$t('e_payment_alert_message')">
                            </div>
                        </b-alert>
                        <b-row align-v="center" class="mb-4">
                            <b-col xs="12" sm="6">
                                <ValidationProvider name="item" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('Item')">
                                        <ParameterSelectbox
                                            :public="true"
                                            :code="selectedType(type)"
                                            v-model="formData.item"
                                            ref="parameter"
                                            :fetch_desired_value="{ is_active: true, key: 'code', value: 'code' }"
                                            :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="3">
                                <ValidationProvider name="amount" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('Amount')">
                                        <b-form-input
                                            type="number"
                                            min="0"
                                            v-model="formData.amount"
                                            readonly
                                            v-check-min-max-value
                                            :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="3">
                                <b-button variant="primary" type="button" block @click="addItem" class="mt-4 mt-sm-0">
                                    {{ $t('Add Item') }}
                                </b-button>
                            </b-col>
                        </b-row>

                        <!-- Tablo -->
                        <b-table
                            striped
                            hover
                            :items="items"
                            :fields="fields"
                            v-if="items.length"
                            responsive
                            class="mt-3"
                        >
                            <template #thead-top>
                                <b-tr>
                                    <b-th colspan="2">Total</b-th>
                                    <b-th>{{ currencyFormat(totalPrice) }}</b-th>
                                </b-tr>
                            </template>
                            <template #cell(amount)="row">
                                {{ currencyFormat(row.item.amount) }}
                            </template>
                            <template #cell(action)="{ index }">
                                <b-button variant="danger" size="sm" @click="items.splice(index, 1)">
                                    {{ $t('delete') }}
                                </b-button>
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>

                <!-- Kredi Kartı Bilgileri -->
                <b-card no-body class="shadow-sm">
                    <b-card-body>
                        <b-row>
                            <b-col xs="12" sm="6" md="4">
                                <ValidationProvider name="card_number" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('credit_card_number')">
                                        <b-form-input
                                            type="text"
                                            v-model="formData.card_number"
                                            v-mask="'#### #### #### ####'"
                                            :state="errors[0] ? false : null"
                                            style="font-weight: bold"
                                        />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="6" md="4">
                                <ValidationProvider name="expiry_month" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('month')">
                                        <b-form-select
                                            v-model="formData.expiry_month"
                                            :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
                                            :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="6" md="4">
                                <ValidationProvider name="expiry_year" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('year')">
                                        <b-form-select
                                            v-model="formData.expiry_year"
                                            :options="years"
                                            :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="6" md="4">
                                <ValidationProvider name="cvv" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('card_code')">
                                        <b-form-input
                                            type="text"
                                            v-model="formData.cvv"
                                            :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        <span class="small text-muted">{{$t('card_code_note')}}</span>

                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="6" md="4">
                                <ValidationProvider name="zip_code" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('zip_code')">
                                        <b-form-input
                                            type="text"
                                            v-model="formData.zip_code"
                                            :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        <span class="small text-muted">{{$t('zip_code_note')}}</span>

                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-col class="d-flex justify-content-center align-items-center mt-3">
                    <b-button variant="success" size="sm" @click="startRequest">
                        {{ $t('Pay Now') }}
                    </b-button>
                </b-col>


            </ValidationObserver>
        </div>
        <CommonModal size="lg" ref="emailVerification" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalContent>
                <div>
                    <EmailVerification v-if="formProgress==='email_verification'" :expiresIn="express_in" @paymentVerify="paymentVerify($event)" @sendEmail="startRequest"/>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import MakePaymentServices from "@/services/MakePaymentServices";
import EmailVerification from "@/modules/makePayment/pages/EmailVerification.vue";
import {setSuccessRoutePage} from "@/modules/makePayment/router/middleware";

export default {
    components: {EmailVerification},
    props:{
        type:{
            type:String,
        }
    },
    metaInfo() {
        return {
            title: this.$t('e_payment'),
        };
    },
    data() {
        return {
            formProgress:null,
            formData:{
                amount:null,
                item:null,
                fees:[],
            },
            express_in:null,
            fields:[
                {key: 'text', label:''},
                {key: 'amount', label:''},
                {key: 'action', label:''},
            ],
            items: []
        }
    },
    watch:{
      'formData.item'(newValue){
          if(newValue){
              const parameter = this.$refs.parameter.getItem(newValue);
              const parseValue = JSON.parse(parameter.data)
              this.formData.amount=parseValue.price;
          }else{
              this.formData.amount=null;
          }
      }
    },
    computed: {
        totalPrice() {
            return this.items.reduce((sum, item) => sum + (parseFloat(item.amount)), 0).toFixed(2);
        },
        years() {
            let year = new Date().getFullYear()
            let index = 0
            const nums = []

            while (index < 11) {
                nums.push(year + index)
                index++
            }
            return nums
        },
    },
    methods:{
        selectedType(type){
            const paymentTpe={
                'bau': 'make_payment_fees',
                'housing': 'make_payment_housing_fees',
                'mentora': 'make_payment_mentora_fees'
            }
            return paymentTpe[type]

        },
        addItem() {
            const parameter = this.$refs.parameter.getItem(this.formData.item);

            const isValue = this.items.filter(item => item.fee === parameter.value)
            if (isValue.length>0){
                return;
            }
            if (!this.formData.fees) {
                return;
            }

            const parseValue = JSON.parse(parameter.data)

            this.items.push({
                text: parameter.text,
                amount:parseValue.price,
                fee:parameter.value
            });

        },
        formClear() {
            this.formProgress=null;
        },
        async startRequest(){
            const isValid= await this.$refs.formReservationModalValidate.validate();
            if (!isValid)return
            this.formData.fees=this.items
            delete this.formData.amount
            delete this.formData.item
            let startPaymentData ={
                email:this.formData.email,
                card_number:this.formData.card_number,
                fees:this.formData.fees,
                related_type:this.type
            }
            try {
                const res = await MakePaymentServices.startPayment(startPaymentData)
                this.$toast.success(this.$t('make_payment_success_message'))
                this.formProgress='email_verification'
                this.express_in=res.data.data.express_in;
                this.$refs.emailVerification.$refs.commonModal.show()

            }catch (error) {
                this.showErrors(error,this.$refs.formReservationModalValidate)
            }
        },
        async paymentVerify(code){
            this.formClear()
            this.$refs.emailVerification.$refs.commonModal.hide()
            const verifyPaymentData ={
                code:code,
                card_holder_name:this.formData.name +' '+this.formData.surname,
                email:this.formData.email,
                country_code:this.formData.country_code,
                explanation:this.formData.note,
                fees:this.formData.fees,
                card_number:this.formData.card_number,
                expiry_month:this.formData.expiry_month,
                expiry_year:this.formData.expiry_year,
                cvv:this.formData.cvv,
                zip_code:this.formData.zip_code,
                related_type:this.type
            }
            try {
                const res = await MakePaymentServices.verifyPayment(verifyPaymentData)
                this.$toast.success(this.$t("api." +res.data.message));
                setSuccessRoutePage(true)
                this.$router.push('/payment-success')


            }catch (error) {
                this.showErrors(error,this.$refs.formReservationModalValidate)
            }
        }
    }
}
</script>
