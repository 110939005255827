
import Api from '@/services/Index';

const startPayment = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/make-payment/start', data);
}

const verifyPayment = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/make-payment/verify', data);
}



export default {
    startPayment,
    verifyPayment
}
